@each $value,
$color in $grays {
  .text-gray-#{$value} {
    color: $color !important;
  }
}

h6 {
  font-weight: 400;
}

.letter-spacing-1 {
  letter-spacing: 1.5px;
}

.tiny-font {
  font-size: .5625rem;
  color: $gray-700;
}

.text-gradient {
  background: $gradient;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.site-heading {
  font-size: $font-size-sm;
  color: $primary;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}