@media print {
  @page {
    size: A4;
    /* Chrome sets own margins, we change these printer settings */
    size: 8.27in 11.69in;
    margin: .5in .5in .5in .5in;
    mso-header-margin: .5in;
    mso-footer-margin: .5in;
    mso-paper-source: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
  }
}


@media all {
  .page-break {
    display: none;
  }

  .header-hidden {
    display: none;
  }
}


@media print {

  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  .bar-chart-block {

    .recharts-wrapper,
    .recharts-surface,
    .recharts-legend-wrapper {
      width: calc(100%) !important;
    }
  }

  .footer-hidden {
    display: none;
  }

  .header-hidden {
    display: block;
  }

  .pagebreak {
    margin-bottom: 3rem;
    margin-top: 3rem;
    display: block !important;
    page-break-before: always !important;
  }

  @page {
    @bottom-left {
      content: counter(page) "/"counter(pages);
    }
  }

}