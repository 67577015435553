.bg-lighter {
  background-color: $gray-200;
}

.bg-success-light {
  background-color: $success-light;
}

.bg-pattern-light {
  background: url("../../assets/img/branding/pattern-bg.png") no-repeat $primary;
  background-size: cover;
}

.bg-pattern-white {
  background: url("../../assets/img/branding/pattern-bg.png") no-repeat $white;
  background-size: cover;
}