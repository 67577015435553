.loader{
  width: 2rem;
  height: 2rem;
  margin: 2rem;
  border-radius: 50%;
  border: 0.3rem solid rgba($primary, 0.3);
  border-top-color: $primary;
  animation: 1.5s spin infinite linear;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}