.btn-secondary {
  color: $white;

  &:hover,
  &:active,
  &:focus {
    color: $white;
  }
}

.btn-outline-secondary:hover {
  color: $white;
  background-color: $secondary;
  border-color: $secondary;
}