@include media-breakpoint-up(lg) {
  .nav-item .submenu{ 
    display: none;
    position: absolute;
    left: 100%;
    top: -11px;

    &-left{ 
      right:100%;
      left:auto;
    }
  }

  .dropdown-menu > li {
    position: relative;

    &:hover{ 
      &> .submenu{ 
        display: block;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .dropdown-menu .dropdown-menu{
    margin-left: 0.7rem; 
    margin-right: 0.7rem; 
    margin-bottom: .5rem;
  }
}