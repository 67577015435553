//amazon channel

.amazon-channel-integration {
  .dropdown {
    button {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:hover {
        color: $gray-500;
        background-color: $white;
        $border-color: $white;
      }

      &#channel_region,
      &#channel_country {
        text-align: left;
        padding: 0.375rem 0.6rem;
      }
    }

    ul {
      &.show {
        width: 100%;
      }
    }
  }
}

//CHANNELS
.channel.not-allowed.bg-white:after,
.system.not-allowed.bg-white:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  content: " ";
  background: $gray-200;
  height: 100%;
  z-index: 3;
}