.product-creation-form {
  .add-new-product {
    top: 50px;
  }
}

.focus-secondary{
	&:focus{
		border: 1px solid $secondary !important;
	}
}

.discount {
  &:hover {
    box-shadow: 0 .5rem 1rem rgba($black, .15) !important;
  }
}