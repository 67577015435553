// Color system
$white: #fff !default;
$gray-100: #F8F9FA !default;
$gray-200: #E9ECEF !default;
$gray-300: #DEE2E6 !default;
$gray-400: #CED4DA !default;
$gray-500: #ADB5BD !default;
$gray-600: #6C757D !default;
$gray-700: #495057 !default;
$gray-800: #343A40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: (100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900,
);

// Body
$body-bg: $gray-100;

// Typography
$font-family-sans-serif: 'Nunito Sans',
sans-serif;
$line-height-base: 1.6;

// Colors
$blue: #000036;
$indigo: #5e72eb;
$purple: #9561e2;
$pink: #f66d9b;
$red: #ea4949;
$orange: #f6993f;
$yellow: #e8b34b;
$green: #8dd350;
$teal: #4dc0b5;
$cyan: #a6deef;


$primary: $blue !default;
$secondary: $indigo !default;
$success: $green !default;
$info: $teal !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-500 !default;
$dark: $gray-700 !default;
$success-light: $teal !default;

$component-active-bg: $secondary !default;

$gradient: linear-gradient(to right, $primary, $pink);

$sidebar-width: 240px !default;
$spacer: 1rem !default;
$border-color: $gray-200 !default;



$font-size-base: 0.875rem !default;
$h1-font-size: $font-size-base * 2.8571428571 !default;
$h2-font-size: $font-size-base * 2.2857142857 !default;
$h3-font-size: $font-size-base * 2 !default;
$h4-font-size: $font-size-base * 1.7142857143 !default;
$h5-font-size: $font-size-base * 1.4285714286 !default;
$h6-font-size: $font-size-base * 1.1428571429 !default;

$small-font-size: 0.75rem !default;
$font-size-sm: $font-size-base * .7142857142 !default;

// $font-size-base: 0.875rem !default;
// $small-font-size: 0.86em !default;

$headings-font-weight: 700 !default;

//text
$text-muted: $gray-400 !default;

//inputs
$input-btn-focus-width: 0 !default;
$input-btn-focus-color-opacity: 0 !default;
$input-placeholder-color: $gray-500 !default;
$input-group-addon-bg: $white !default;
$input-bg:$white !default;

//tooltips
$tooltip-color: $white;
$tooltip-bg: $black;
// $tooltip-border-radius:             $border-radius;


//buttons
$btn-padding-y: 0.375rem !default;
// $btn-padding-x:               3rem !default;
$btn-font-weight: 600 !default;

//dropdown
$c-padding-x: $spacer !default;
$dropdown-border-color: $border-color !default;

$dropdown-item-padding-y: $spacer / 3 !default;

//table
$table-bg: $white !default;
$table-cell-padding-y: .75rem !default;
$table-cell-padding-x: .75rem !default;

$form-check-input-checked-bg-color: $primary !default;

$badge-font-size: 1em !default;
$badge-padding-y: .6em !default;
$badge-padding-x: 1.25em !default;

$card-border-color: $border-color !default;

$hr-color: $gray-400 !default;

$placeholder-opacity-max: .4;

$rt-toast-background: $white !default;

$rt-color-default: $white !default;
$rt-color-dark: $dark !default;
$rt-color-info: $info !default;
$rt-color-success: $success !default;
$rt-color-warning: $warning !default;
$rt-color-error: $danger !default;

$rt-text-color-default: $gray-500 !default;
$rt-text-color-dark: $white !default;

$enable-negative-margins: true;