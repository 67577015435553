.nav-link {
  color: $gray-500;
  border: solid 1px transparent;
  border-radius: 4px;
  margin-bottom: .5rem;
  padding: .1rem 0;

  &.active {
    color: $secondary;
  }

  &:hover {
    color: rgba($secondary, .8);
  }
}

.navbar-rotate {
  -moz-transition: all .3s linear;
  -webkit-transition: all .3s linear;
  transition: all .3s linear;
}

.navbar-rotate.down {
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.general-nav {
  .dropdown {
    .dropdown-menu {
      ul {
        &.list-inline>li {
          vertical-align: top;
          padding: 2rem;
        }
      }
    }
  }
}

#settings {
  .nav-link {
    color: $primary;
    border: solid 1px transparent;
    border-radius: 0px;
    margin-bottom: .5rem;
    padding: .1rem 0;

    &.active {
      color: $secondary;
    }

    &:hover {
      color: rgba($secondary, .8);
    }
  }
}