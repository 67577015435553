.dropdown.filter-dropdown {
  .dropdown-menu.full-width {
    min-width: 30rem;
  }

  .form-select-sm {
    max-width: 5rem;
  }

  .status-select {
    &-wrap {
      width: 14rem;
    }
  }
}

#filter_dropdown {
  .css-g1d714-ValueContainer {
    padding: 0px 0px;
  }
}