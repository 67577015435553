.sidebar {
  width: 100%;
  max-width: $sidebar-width;

  position: fixed;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;

  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
  transition: max-width .3s;
  background-color: $primary;

  .border-top {
    border-color: rgba($secondary, .3) !important;
  }

  .sidebar-inner {
    position: relative;
  }

  ul.nav {
    a {
      text-decoration: none;
    }

    >li {
      width: 100%;

      a {
        display: block;
        margin-bottom: 0;
        color: $gray-500;

        &.active {
          color: $secondary;
        }
      }

      &>a {

        &.has-child {
          margin-bottom: 0.2rem;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &::after {
            content: "";
            display: inline-block;
            margin-left: 0.255em;
            vertical-align: 0.255em;
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
          }

          &.active {
            &::after {
              content: "";
              display: inline-block;
              margin-left: 0.255em;
              vertical-align: 0.255em;
              border-top: 0;
              border-right: 0.3em solid transparent;
              border-bottom: 0.3em solid;
              border-left: 0.3em solid transparent;
            }

            &+ul {
              display: block;
            }
          }
        }

        &+ul {
          display: none;
        }

      }

      ul {
        padding-left: 1.5rem;
        list-style: none;

        li a {
          margin-bottom: 0.2rem;
        }
      }
    }
  }

  .profile .dropdown>a {
    color: $gray-200;
  }

}

.panel {
  border: 0;
  margin-bottom: 0;
}