.content {
  margin-left: $sidebar-width;
  max-width: calc(100% - #{$sidebar-width});
  overflow: visible;
  transition: all .3s;
  height: 100vh;
}

// .content-height {
//   min-height:calc(100vh - 200px);
// }