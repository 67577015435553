.modal-dialog{
  .input-group{
    .multiselect-container{
      position: relative;
      flex: 1 1 auto;
      width: 1%;
      min-width: 0;
      background: $white;
      
      .search-wrapper{
        font-weight: 600;
        padding:6px;
        border-left: none;
        border-bottom-left-radius:0; 
        border-top-left-radius:0;
        input{
          margin-top: 0;
        }
        .chip{
          background: $gray-700;
        }
      }
    }
  }
}

.permissions-data{
  span{
    &::after{
      content: "\002C";
      margin-right: 4px;
    }
  }
}

.permissions-data{
  span:only-child{
    &::after{
      content: "";
    }
  }
}

.permissions-data{
  span:last-child{
    &::after{
      content: "";
    }
  }
}

