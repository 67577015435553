body,
html {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
}

#main {
  height: 100%;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.page pre {
  font-family: inherit;
  font-size: inherit;
  white-space: pre-line;
}

.cursor {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

label {
  font-weight: 600;
}

.not-allowed {
  cursor: not-allowed !important;
}

.dropdown-menu {
  white-space: nowrap;
}

.border-right-line {
  &:last-child {
    border: none !important;
  }
}

//input and multiselect
.input-group {
  .input-group-text {
    border-right: none;

    :not(:first-child) {
      margin-left: -1px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  input {
    font-weight: 600;
    padding-left: 0.5rem;

    &.noborder {
      border-left: none;
    }

    &:focus {
      outline: 0;
      border-color: $gray-400;
    }
  }
}

.multiselect-container {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  background: $white;

  .search-wrapper {
    font-weight: 600;
    padding: 6px;

    input {
      margin-top: 0;
    }

    .chip {
      background: $gray-700;
    }
  }

  .optionContainer {
    li {
      border-bottom: 1px solid $gray-200;
      font-weight: 600;

      &.highlight {
        background: $gray-200;
        color: $gray-700;
      }

      &:hover {
        background: $gray-200;
        color: $gray-700;
      }
    }
  }
}

.form-control:disabled {
  background-color: $white;
}

.table {
  margin: 0;

  th {
    font-size: .86em;
    color: $gray-500;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  tbody {
    tr {
      background-color: $white;
      border: none;

      td {
        max-width: 150px;
        text-overflow: ellipsis;
        font-size: .86em;
        color: $gray-700;
        border: none;
        word-wrap: break-word;
        word-break: break-word;
        vertical-align: middle;
        // padding: 1.04rem 0.75rem;

        .active-icon {
          visibility: hidden;
        }

        @media screen and (min-width:1200px) {
          max-width: 400px;
        }
      }

      &:hover {
        .active-icon {
          visibility: visible;
          @include transition;
        }
      }
    }
  }

  tfoot>*>* {
    border: none;
  }
}

.table> :not(:first-child) {
  border-top: none;
}

.modal-dialog {
  .modal-header {
    button {
      border: 0;
      background: transparent;
      position: absolute;
      right: 0;
      top: -10%;
      font-size: 1rem;
      color: $white;
    }

    .sr-only {
      display: none;
    }
  }
}


.overflow-prevent {
  overflow-x: hidden;
  max-height: 500px;
  overflow-y: auto;
}

.selection-box {
  height: 15px;
  width: 15px;
  display: block;
  border: 1px solid $black;
}

.error-field {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  text-indent: -9999px;
}

svg.rotate {
  animation: 1.5s spin infinite reverse;
}

.sync-status {

  .nav-tabs,
  .nav-link.active {
    border-color: $secondary !important;
  }

  .nav-tabs,
  .nav-link {
    border-color: $gray-300 !important;
    padding: 0.5rem 1rem;
  }
}

.sync-content {
  border-bottom: 1px solid $gray-300;

  &:last-child {
    border: none;
  }

  .active-icon {
    visibility: hidden;
  }

  &:hover {
    .active-icon {
      visibility: visible;
      @include transition;
    }
  }
}

.form-control:disabled {
  background-color: $white;
}

.css-1fhf3k1-control {
  background-color: $white !important;
}

.tox-tinymce {
  border-radius: 0.25rem !important;
}

//Product page warehouse content
.warehouse {
  &:last-child {
    margin-bottom: 0 !important;
  }
}

.overview{
  .overview-card-cols:not(:last-child){
    margin-bottom: 1rem;
  }
}