.login-page-wrapper{
  height: 100vh;
  .container-fluid{
    height: 100%;
    .row{
      height:100%;
    }
  }
  .login-sidebar-container{
    background:url("../../assets/img/theme/login-template.png");
    background-size: cover;
    background-repeat: no-repeat;
    position:relative;
  }

  .login-textblock-wrapper{
    height: inherit;
    .text-block{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    .copyright-block{
      position: absolute;
      bottom: 0;
    }
  }
}


  

