.user-creation-form{
  .multiselect-container{
    background: $white;
    .search-wrapper{
      padding: 0.375rem 0.75rem;
      input{
        margin: 0;
        padding: 0;
        font-weight: 600;
      }
      .chip{
        background: $gray-700;
      }
    }
    .optionContainer{
      li{
        font-weight: 600;
        border-bottom: 1px solid $gray-200;
        &.highlight{
          background: $white;
          color: $gray-700;
          &:hover{
            background: $gray-200;
            color: $gray-700;
          }
        }
        &:hover{
          background: $gray-200;
          color: $gray-700;
        }
      }
    }
  }
  .dropdown{
    .btn{
      border: 1px solid $gray-400;
      padding: 0.375rem 0.75rem;
      position: relative;
      background: $white;
      color: $gray-700;
    }
    ul{
      width: 100%;
      li{
        font-size: $font-size-base;
        font-weight: 600;
        .form-check-input{
          &:checked{
            background-color: $gray-700;
            border-color: $gray-700;
          }
        }
        &:hover{
          background: $gray-200;
          cursor: pointer;
        }
        input{
          margin-right: 1rem;
        }
        p{
          width: 130px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
  }
}

//sweetalert

.swal2-popup{
  .swal2-icon{
    width: 3rem;
    height: 3rem;
    &.swal2-warning{
      border-color: $danger;
    }
    .swal2-icon-content{
      font-size: 2.5rem;
      color: $danger;
    }
  }
  .swal2-title{
    font-size: 28px;
  }
  .swal2-content{
    #swal2-content{
      font-size: 14px;
    }
  }
  .swal2-actions{
    button{
      display: inline-block;
      font-weight: 400;
      padding: 0.375rem 0.75rem;
      border-radius: 0.25rem;
      font-size: 0.875rem;
      &.swal2-styled{
        &:focus{
          box-shadow: none;
        }
      }
      &.swal2-confirm{
        color: $white;
        background-color: $danger;
        border: 1px solid transparent;
      }
      &.swal2-cancel{
        color: $gray-700;
        background-color: transparent;
        border: 1px solid $gray-700;
        &:hover{
          color: $white;
          background-color: $gray-700;
          @include transition
        }
      }
    }
  }
}