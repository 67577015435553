input[type="radio"][id^="invoice_type_"] {
    display: none;
  }
  
  .form-check-label {
    border: 1px solid #fff;
    cursor: pointer;
    display: block;
    position: relative;
   
  }
  
  .form-check-label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid grey;
    position: absolute;
    top: -10px;
    left: -10px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    transition-duration: 0.4s;
    transform: scale(0);
  }
  
  .form-check-label img {
    height: 300px;
    width: 200px;
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
  }
  
  input[type="radio"]:checked ~ .form-check-label {
    border-color: $blue;
  }
  
  input[type="radio"]:checked ~ .form-check-label:before {
    display: inline-block;
    content: "";
    background-color: $blue;
    transform: scale(1);
  }
  
  input[type="radio"]:checked ~ .form-check-label img {
    transform: scale(0.9);
    box-shadow: 0 0 5px #333;
    z-index: -1;
  }

  .ui-check {
    top: -5px;
    left: -5px;
    text-align: center;
    color: $white;
  }
  